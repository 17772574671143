






































































































































































































































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import { InjuryLookupModel, InjuryModel, InjuryQualityControlModel } from '@/core/webapi';
import { ReportLookupService, ApiService } from '@/core/services';
import { BaseFormComponent, InputTextareaCpt, InputSelectCpt, ValidationHintCpt, SpinnerCpt } from '@/core/components';
import {
  QcInputFieldCpt,
  QcInputTextareaCpt,
  QcInputDateCpt,
  QcInputRadioCpt,
  QcInputSwitchCpt,
  QcInputSwitchWithTextareaCpt,
  QcInputSelectCpt,
  QcInputSwitchDateCpt,
  QcOsicsCpt,
  AnalystDataCleanupInfoCpt,
} from '../components';
import { QcStatus, StudyTypes } from '@/core/constants';

@Component({
  components: {
    InputTextareaCpt,
    InputSelectCpt,
    ValidationHintCpt,
    SpinnerCpt,
    QcInputFieldCpt,
    QcInputTextareaCpt,
    QcInputDateCpt,
    QcInputRadioCpt,
    QcInputSwitchCpt,
    QcInputSwitchWithTextareaCpt,
    QcInputSelectCpt,
    QcInputSwitchDateCpt,
    QcOsicsCpt,
    AnalystDataCleanupInfoCpt,
  },
})
export default class InjuryFormView extends BaseFormComponent {
  lookups = {} as InjuryLookupModel;
  report = new InjuryModel({ modelState: {} } as any);
  qualityControl = new InjuryQualityControlModel({
    medicalImagingTypeIds: [],
    invasiveProcedureTypeIds: [],
    modelState: {},
  } as any);
  isQcComplete = false;
  showDialog = false;
  pickerOptions = {
    disabledDate(time: any) {
      return time.getTime() > Date.now();
    },
  };
  loading = true;

  get injuryId() {
    return this.getUrlParamAsNumber('injuryId');
  }

  get organisations() {
    return ReportLookupService.filterOrgs(this.lookups, StudyTypes.Elite);
  }

  get studies() {
    return ReportLookupService.filterOrgStudies(this.lookups, this.report.organisationId, StudyTypes.Elite);
  }

  get isSevensStudy() {
    return this.studies?.find(p => p.id === this.report.studyId)?.gameFormatId === 2;
  }

  get injuryTimes() {
    const selectedStudy = this.studies?.find(p => p.id === this.report.studyId);
    return this.lookups.injuryTimes?.filter(p => p.gameFormatId === selectedStudy?.gameFormatId) ?? [];
  }

  get playingPositions() {
    return ReportLookupService.getPlayingPositions(this.lookups.playingPositions!, this.studies, this.report.studyId);
  }

  async created() {
    try {
      await this.initializeModel();
      await this.initializeLookupData();
    } finally {
      this.loading = false;
    }
  }

  async onSubmitForm() {
    if (this.isQcComplete) {
      this.qualityControl.qcStatusId = QcStatus.Complete;
      await this.completeFormSubmission();
    } else {
      this.showDialog = true;
    }
  }

  onDialogClose() {
    this.showDialog = false;
  }

  async onDialogButton(requireReporterInfo: boolean) {
    this.qualityControl.qcStatusId = requireReporterInfo ? QcStatus.AwaitingReporter : QcStatus.AwaitingAnalyst;
    this.showDialog = false;
    await this.completeFormSubmission();
  }

  async completeFormSubmission() {
    this.qualityControl.id
      ? await ApiService.injuryQualityControl()
          .updateInjuryQualityControl(this.qualityControl)
          .handleValidationErrors(this.qualityControl)
      : await ApiService.injuryQualityControl()
          .createInjuryQualityControl(this.qualityControl)
          .handleValidationErrors(this.qualityControl);

    this.$notify({
      title: this.$t('Success') as string,
      message: this.$t('Injury review saved') as string,
      type: 'success',
    });

    this.$router.push('/injuries');
  }

  private async initializeModel() {
    if (this.injuryId) {
      const response = (await ApiService.injuryQualityControl().getInjuryQualityControl(this.injuryId)).data;

      _.extend(this.report, response.report);
      _.extend(this.qualityControl, response.qualityControl);
      this.isQcComplete = response.qualityControl.qcStatusId === QcStatus.Complete;
    }
  }

  private async initializeLookupData() {
    this.lookups = (await ApiService.injuries().getInjuryFormLookupData(this.report.playerId!)).data;
    ReportLookupService.resolveOrgStudyFilterIds(this.lookups, this.report, false, StudyTypes.Elite);
  }
}
