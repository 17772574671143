













































































































import { Component } from 'vue-property-decorator';
import _ from 'lodash';

import AppVue from '@/AppVue.vue';
import { CommunityInjuryCollectionLookupModel, CommunityInjuryCollectionModel } from '@/core/webapi';
import { ApiService, ReportLookupService } from '@/core/services';
import {
  GridFactory,
  GridModel,
  GridFilter,
  GridSearchCpt,
  GridPagerCpt,
  GridSelectFilterCpt,
  StudyOrgFilterCpt,
} from '@/core/components/grid';
import { QcStatusTagCpt } from '../components';
import { StudyTypes } from '@/core/constants';
import InjuriesGridHeaderCpt from './InjuriesGridHeaderCpt.vue';

@Component({
  components: {
    GridSearchCpt,
    GridPagerCpt,
    GridSelectFilterCpt,
    StudyOrgFilterCpt,
    QcStatusTagCpt,
    InjuriesGridHeaderCpt,
  },
})
export default class CommunityInjuriesGridView extends AppVue {
  grid = {} as GridModel<CommunityInjuryCollectionModel>;
  lookups = {} as CommunityInjuryCollectionLookupModel;
  studyTypeId = StudyTypes.Community;

  canAccessDetails(item: CommunityInjuryCollectionModel) {
    return (this.isReporter && item.createdById === this.currentUserId) || this.isAnalyst;
  }

  async created() {
    this.grid = GridFactory.create({
      filterMethod: filter => ApiService.communityInjuries().filterCommunityInjuries(filter),
      routeName: 'communityInjuries',
      defaultFilterOverrides: new GridFilter(await this.initializeFilters()),
    });
  }

  async initializeFilters() {
    this.lookups = (await ApiService.communityInjuries().getCommunityInjuryCollectionLookupData()).data;

    let initialFilter = {
      search: '',
      organisationId: null,
      studyId: null,
      returnStatusId: null,
      qcStatusId: null,
    };

    return ReportLookupService.resolveStudyOrOrgIds(
      this.lookups,
      initialFilter,
      this.isAnalyst || this.isDevEnv,
      this.studyTypeId,
      this.isReporter,
    );
  }

  async deleteItem(id: number) {
    if (!confirm(this.$t('Are you sure you want to delete this item?') as string)) {
      return;
    }

    await ApiService.communityInjuries().deleteCommunityInjury(id);
    this.grid.update();
  }
}
