

























































import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { CatastrophicInjuryCollectionModel } from '@/core/webapi';
import { ApiService } from '@/core/services';
import { GridCollection } from '@/core/components';
import InjuriesGridHeaderCpt from './InjuriesGridHeaderCpt.vue';

@Component({
  components: {
    InjuriesGridHeaderCpt,
  },
})
export default class CatastrophicInjuriesGridView extends AppVue {
  collection = {} as GridCollection<CatastrophicInjuryCollectionModel>;

  get injuries() {
    return this.collection?.items ?? [];
  }

  async created() {
    await this.initializeData();
  }

  async deleteItem(id: number) {
    if (!confirm(this.$t('Are you sure you want to delete this item?') as string)) {
      return;
    }

    await ApiService.catastrophicInjuries().deleteCatastrophicInjury(id);
    await this.initializeData();
  }

  private async initializeData() {
    this.collection.items = [];
    this.collection = (await ApiService.catastrophicInjuries().filterCatastrophicInjuries()).data;
  }
}
